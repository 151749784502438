import axios, { AxiosError } from 'axios';
import { stringify } from 'qs';
import { v4 as uuidv4 } from 'uuid';
import { useNetwork } from 'choco-miniapp-debugbar';
import { AuthService } from '@/services/auth';
import { SentryService } from '@/services/sentry';
import i18n from '@/utils/plugins/i18n';
import { handleUnauthorizedResponse } from './functions';
import {
	ApiProxyResponse,
	isApiProxyResponse
} from './types/interfaces/api-proxy-response-data';
import { isApiProxyErrorData } from './types/interfaces/api-proxy-error-data';
import { AUTH_REQUEST, GET_TRACK_ID_REQUEST } from './constants';

const TRACKED_APIS = [AUTH_REQUEST, GET_TRACK_ID_REQUEST];
const baseURL = import.meta.env.VITE_API_PROXY;
const { addRequest, addRequestResponseById } = useNetwork();

const pushApiError = (error: any) => {
	if (TRACKED_APIS.includes(error.response?.config?.url || '')) {
		SentryService.pushApiError(error);
	}
};

const client = axios.create({
	baseURL,
	paramsSerializer: params => stringify(params),
	transformRequest: [data => JSON.stringify(data)]
});

client.interceptors.request.use(
	config => {
		const fingerprint = uuidv4();
		config.headers['X-Fingerprint'] = fingerprint;
		config.headers['X-Language'] = i18n.global.locale.value;

		if (!config.headers['Content-Type']) {
			config.headers['Content-Type'] = 'application/json';
		}

		if (!config.headers['X-Idempotency-key']) {
			config.headers['X-Idempotency-key'] = uuidv4();
		}

		if (AuthService.isAuthorized()) {
			// eslint-disable-next-line no-param-reassign
			config.headers.Authorization = `Bearer ${AuthService.state.accessToken}`;
		}

		addRequest({
			id: fingerprint,
			headers: config.headers,
			params: config.params,
			body: config.data,
			url: config.url ? `${baseURL}/${config.url}` : baseURL,
			method: config.method || 'GET'
		});

		return config;
	},
	error => Promise.reject(error)
);

client.interceptors.response.use(
	response => {
		const fingerprint = response.config.headers['X-Fingerprint'];
		if (fingerprint) {
			addRequestResponseById(fingerprint, response.status, response.data);
		}

		const data: ApiProxyResponse<any> | any = response.data;

		// gateway
		if (isApiProxyResponse<unknown>(data)) {
			if (data.error_code && data.error_code !== 200) {
				pushApiError(response);

				if (data.error_code === 401) {
					return handleUnauthorizedResponse(client, response);
				}

				return Promise.reject(data);
			}
		}

		return response;
	},
	(error: Error | AxiosError) => {
		if (axios.isAxiosError(error)) {
			const fingerprint = error.config?.headers['X-Fingerprint'];
			if (fingerprint) {
				addRequestResponseById(
					fingerprint,
					error.status || 404,
					error.response?.data
				);
			}

			// назначаю message первой ошибки в ApiProxy
			const data = error.response?.data;
			if (isApiProxyErrorData(data) && data.errors?.length) {
				error.message = data.errors[0].title;
			} else if (isApiProxyResponse<unknown>(data)) {
				error.message = data.message;
			}

			// обработка 401
			const statusCode = error.response?.status || 0;
			if (statusCode === 401) {
				return handleUnauthorizedResponse(client, error);
			}

			pushApiError(error);
		}

		return Promise.reject(error);
	}
);

export { client };
