import { ITown } from '@/types/interfaces/common/address';

const VISIBLE_TOWNS: ITown[] = [
	{
		id: '1',
		title: 'Алматы',
		coordinates: {
			latitude: 43.222015,
			longitude: 76.85125
		}
	},
	{
		id: '2',
		title: 'Астана',
		coordinates: {
			latitude: 51.160522,
			longitude: 71.47036
		}
	},
	{
		id: '3',
		title: 'Шымкент',
		coordinates: {
			latitude: 42.315514,
			longitude: 69.586916
		}
	},
	{
		id: '4',
		title: 'Семей',
		coordinates: {
			latitude: 50.404964,
			longitude: 80.24919
		}
	},
	{
		id: '5',
		title: 'Караганда',
		coordinates: {
			latitude: 49.804684,
			longitude: 73.109383
		}
	},
	{
		id: '6',
		title: 'Атырау',
		coordinates: {
			latitude: 47.105045,
			longitude: 51.924622
		}
	},
	{
		id: '7',
		title: 'Актау',
		coordinates: {
			latitude: 43.641098,
			longitude: 51.198509
		}
	},
	{
		id: '9',
		title: 'Темиртау',
		coordinates: {
			latitude: 50.058761,
			longitude: 72.953424
		}
	},
	{
		id: '11',
		title: 'Уральск',
		coordinates: { latitude: 51.204025, longitude: 51.370537 }
	},
	{
		id: '12',
		title: 'Талдыкорган',
		coordinates: {
			latitude: 45.017837,
			longitude: 78.382105
		}
	},
	{
		id: '20',
		title: 'Усть-Каменогорск',
		coordinates: { latitude: 49.948313, longitude: 82.627857 }
	},
	{
		id: '24',
		title: 'Конаев',
		coordinates: {
			latitude: 43.854849,
			longitude: 77.061581
		}
	}
];

const ALL_TOWNS: ITown[] = [
	...VISIBLE_TOWNS,
	{
		id: '8',
		title: 'Павлодар',
		coordinates: { latitude: 52.285577, longitude: 76.940722 }
	},
	{
		id: '13',
		title: 'Кызылорда',
		coordinates: { latitude: 44.842563, longitude: 65.502554 }
	},
	{
		id: '14',
		title: 'Туркестан',
		coordinates: { latitude: 0, longitude: 0 }
	},
	{
		id: '15',
		title: 'Экибастуз',
		coordinates: { latitude: 51.72969, longitude: 75.326622 }
	},
	{
		id: '16',
		title: 'Петропавловск',
		coordinates: { latitude: 54.86186, longitude: 69.139617 }
	},
	{
		id: '17',
		title: 'Костанай',
		coordinates: { latitude: 53.214486, longitude: 63.632082 }
	},
	{
		id: '18',
		title: 'Кокшетау',
		coordinates: { latitude: 53.29, longitude: 69.42 }
	},
	{
		id: '19',
		title: 'Тараз',
		coordinates: { latitude: 42.899657, longitude: 71.392727 }
	},
	{
		id: '21',
		title: 'Рудный',
		coordinates: { latitude: 0, longitude: 0 }
	},
	{
		id: '22',
		title: 'Балхаш',
		coordinates: { latitude: 0, longitude: 0 }
	},
	{
		id: '23',
		title: 'Жезказган',
		coordinates: { latitude: 0, longitude: 0 }
	}
];

export { VISIBLE_TOWNS, ALL_TOWNS };
