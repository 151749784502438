import { AuthService } from '@/services/auth';
import { Analytics } from '@/services/analytics';
import i18n from '@/utils/plugins/i18n';
import { getStorageParsedData } from '@/utils/functions';

interface IGradeLibraryInstance {
	init: () => Promise<boolean>;
	destroy: () => void;
	mount: () => void;
}

const ORDERS_WERE_SHOWN_KEY = 'ordersWithGradingWereShown';
const appEnv = import.meta.env.VITE_ENV;
const gradingEnv = appEnv === 'production' ? 'production' : 'stage';

const onForbidden = () => {
	return Promise.reject(new Error('Нет токена')); // добавить, когда будет готов метод
};

const initGrading = async (
	filialId: number,
	orderId: number,
	amount: number,
	showCongrats: boolean,
	domSelector?: string
): Promise<IGradeLibraryInstance> => {
	const [{ MyGradeLibrary }] = await Promise.all([
		import('grading-widget'),
		import('grading-widget/dist/grading-widget.css')
	]);

	const widget = new MyGradeLibrary({
		filialId: String(filialId),
		orderId: String(orderId),
		amount: String(amount),
		onForbidden,
		token: AuthService.state.accessToken,
		showCongrats,
		environment: gradingEnv,
		lang: i18n.global.locale.value,
		onError: (msg: string) => console.log(msg),
		widgetTagName: domSelector || '#grading-widget',
		logAmplitudeEvent: Analytics.logEvent.bind(Analytics)
	});

	return {
		init: widget.init,
		mount: widget.mount,
		destroy: widget.destroy
	};
};

function needDetermineGrading(filialId: number, orderId: number): boolean {
	let wereShownOrders: number[] =
		getStorageParsedData(ORDERS_WERE_SHOWN_KEY)! || [];

	if (wereShownOrders.includes(orderId)) {
		return false;
	}

	if (wereShownOrders.length >= 10) {
		wereShownOrders = [];
	}

	wereShownOrders.push(orderId);
	localStorage.setItem(ORDERS_WERE_SHOWN_KEY, JSON.stringify(wereShownOrders));
	return true;
}

export { IGradeLibraryInstance, initGrading, needDetermineGrading };
