import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { AuthService } from '@/services/auth';
import { ClientService } from '@/services/client';
import providers from '@/utils/plugins/di';

/**
 * Очистка данных авторизации и обновление страницы
 */
const logout = (): void => {
	AuthService.logout();
	window.location.reload();
};

/**
 * Обработка 401. Обернул в Promise, так как нужно время для обновления страницы/редиректа на Oauth
 */
export default function (
	client: AxiosInstance,
	response: AxiosResponse | AxiosError
): Promise<any> {
	return new Promise(resolve => {
		if (
			Object.prototype.hasOwnProperty.call(response.config, '_retryAttempts')
		) {
			return logout();
		}

		const config = { ...response.config, _retryAttempts: 1 };

		if (ClientService.isWebView()) {
			return providers.application
				.authorize()
				.then(() => {
					resolve(client(config));
				})
				.catch(() => {
					logout();
				});
		}

		AuthService.goToOauth();
	});
}
