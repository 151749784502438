<template>
	<UiModal ref="root" :closeable="false">
		<BaseAction @confirm="onConfirm">
			<template #text>
				{{
					isReactNative
						? `Просим обновить приложение до последней версии, чтобы получить доступ к новым функциям`
						: 'Оплатите счет с помощью приложения Mamma Mia и получите 10% кешбэк на первую оплату'
				}}
			</template>
			<template #confirm>
				{{ isReactNative ? 'Обновить' : 'Скачать приложение' }}
			</template>
		</BaseAction>
	</UiModal>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import BaseAction from '@/components/base/BaseAction.vue';
import UiModal from '@/components/ui/UiModal.vue';
import { injectStrict } from '@/utils/functions';
import { ProvidersKey } from '@/types/symbols';

interface Props {
	link: string;
}

defineProps<Props>();

const providers = injectStrict(ProvidersKey);

const root = ref<InstanceType<typeof UiModal> | null>(null);
const isReactNative = !!providers.application.getCustomConfig();

const onConfirm = () => {
	window.open('https://onelink.to/t8sm39', '_blank');
};

onMounted(() => {
	root.value?.open();
});
</script>
