import { useI18n } from 'vue-i18n';
import { debounce } from '../functions';
import { setLocaleToStorage } from '../plugins/i18n';
import { UserService } from '@/services/user';
import { AuthService } from '@/services/auth';

const useLanguage = () => {
	const { locale } = useI18n();

	const updateProfile = debounce(lang => {
		UserService.updateProfileLanguage(lang);
	}, 500);

	const updateLanguage = (value: string, strict = false) => {
		locale.value = value;
		setLocaleToStorage(value);

		if (strict && AuthService.isAuthorized()) {
			updateProfile(value);
		}
	};

	return {
		updateLanguage
	};
};

export default useLanguage;
