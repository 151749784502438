import { reactive, readonly } from 'vue';
import { NEAR_CATEGORY } from '../navigator/constants';
import { ClientService } from '@/services/client';
import { getFilials, getCleanFilial, getFilialsByBrand } from '@/services/api';
import { DeliveryService } from '@/services/delivery';
import { SERVICES } from '@/utils/constants';
import { PaginationWrapperModel } from '@/utils/models';
import { IFilial } from '@/types/interfaces/navigator';

interface ICallbacks {
	onStart: () => unknown;
	onFinish: () => unknown;
	onError: (error: Error) => unknown;
}

interface IState {
	filials: PaginationWrapperModel<IFilial>;
}

const state = reactive<IState>({
	filials: new PaginationWrapperModel()
});

interface FetchFilialPayload {
	brand: string;
	brand_id?: string;
	page?: number;
}

const fetchFilials = (
	{ brand, brand_id, page }: FetchFilialPayload,
	abort?: AbortController
) => {
	if (state.filials.hasResults() && !page) {
		return Promise.resolve(state.filials);
	}

	let coordinates = ClientService.state.coordinates;
	if (ClientService.state.service.id === SERVICES.delivery.id) {
		coordinates = DeliveryService.activeAddressCoordinates.value;
	}

	const payload = {
		include: 'promo',
		service: ClientService.state.service.id,
		town: ClientService.state.townId,
		coordinates,
		search: brand_id ? undefined : brand,
		brand_id,
		page: page || 1,
		url: NEAR_CATEGORY.payload.url
	};

	return getFilials(payload, abort).then(response => {
		state.filials.addResults(response);
		return state.filials;
	});
};

const loadMoreFilials = (
	payload: FetchFilialPayload,
	cb: ICallbacks = {
		onStart: () => {},
		onError: () => {},
		onFinish: () => {}
	}
) => {
	if (state.filials.page < state.filials.lastPage) {
		cb.onStart();
		return fetchFilials({
			...payload,
			page: state.filials.page + 1
		})
			.catch((error: Error) => {
				cb.onError(error);
			})
			.finally(() => cb.onFinish());
	}

	return Promise.resolve(state.filials);
};

const fetchSubFilials = (subbrand: string, page = 1) => {
	return getFilials({
		include: 'promo',
		town: ClientService.state.townId,
		coordinates: ClientService.state.coordinates,
		search: subbrand,
		page
	});
};

const fetchFilialLink = (filialId: number) => {
	return getCleanFilial(filialId).then(res => {
		const whatsapp = res.data.location.extra?.social_networks.find(network => {
			return network.name === 'whatsapp';
		});

		if (!whatsapp) {
			throw new Error('NO_WHATSAPP_ERROR');
		}

		return whatsapp.link;
	});
};

const clearFilials = () => {
	state.filials.clearResults();
};

export const BrandsService = {
	state: readonly(state) as IState,
	fetchFilials,
	fetchSubFilials,
	fetchFilialLink,
	fetchFilialsByBrand: getFilialsByBrand,
	clearFilials,
	loadMoreFilials
};
