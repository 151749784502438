import { ANALYTICS_INSTANCES, events } from './enums';
import { AnalyticsCommon } from './common.model';
import { AuthService } from '@/services/auth';
import { ClientService } from '@/services/client';
import { CLIENT_ID, NATIVE_APP_AGENT } from '@/utils/constants';

const amplitudeKey = import.meta.env.VITE_AMPLITUDE_KEY;

class TakeawayAnalytics extends AnalyticsCommon<events> {
	constructor(key: string) {
		super(key, ANALYTICS_INSTANCES.TAKEAWAY);
	}

	getProjectType() {
		if (ClientService.isWebView()) {
			return 'miniapp';
		}

		if (navigator.userAgent.includes(NATIVE_APP_AGENT)) {
			return 'partnerapp';
		}

		return 'web';
	}

	async logEvent(event: events, payload: object | null = {}) {
		const userId = AuthService.getUserId();
		const projectType = this.getProjectType();
		const serviceName = ClientService.state.service.id;

		const data = payload
			? {
					project_id: CLIENT_ID,
					project_name: 'digital restaurant',
					project_type: projectType,
					service_name: serviceName,
					...payload
			  }
			: {};

		this.log(event, data, userId);
	}
}

const Analytics = new TakeawayAnalytics(amplitudeKey);

export { Analytics, events };
export * from './composables';
