import {
	getBalance,
	getUser,
	getUserProfile,
	setProfileLanguage,
	updateUser,
	deleteAvatar,
	postAvatar
} from '@/services/api';
import { IUserPayload } from '@/types/interfaces/user';

/**
 * Получение данных о пользователе
 */
const fetchUser = () => getUser();

/**
 * Получение профиля пользователя (содержит последний выбранный язык)
 */
const fetchProfile = () => getUserProfile();

/**
 * Получение баланса пользователя
 */
const fetchBalanceAmount = (): Promise<number> => {
	return getBalance()
		.then(response => response.data.bonus.KZT + response.data.money.KZT)
		.catch(() => 0);
};

/**
 * Обновление языка в профиле пользователя
 */
const updateProfileLanguage = (language: string) => {
	return setProfileLanguage(language);
};

/**
 * Обновление данных о пользователе
 */
const setUserProfile = (payload: IUserPayload) => updateUser(payload);

const UserService = {
	fetchUser,
	fetchProfile,
	fetchBalanceAmount,
	updateProfileLanguage,
	setUserProfile,
	deleteAvatar,
	uploadAvatar: postAvatar
};

export { UserService };
