import { SERVICES, VISIBLE_TOWNS } from '@/utils/constants';
import { ILoader, IService } from '@/types/interfaces/common';
import { APP_RUNTIME } from '@/types/enums/common';
import { AppCustomConfig } from '@/types/interfaces/app';
import { ITown } from '@/types/interfaces/common/address';

class CommonApplication {
	protected runtimeName: APP_RUNTIME;
	protected loader: ILoader = {
		on: () => {},
		off: () => {},
		handle: () => {}
	};

	constructor(runtimeName: APP_RUNTIME) {
		this.runtimeName = runtimeName;
	}

	isWebView(): boolean {
		return this.runtimeName === APP_RUNTIME.WEB_VIEW;
	}

	getRuntimeName(): APP_RUNTIME {
		return this.runtimeName;
	}

	setLoader(loader: ILoader): void {
		this.loader = loader;
	}

	showLoader(): void {
		this.loader.on();
	}

	hideLoader(): void {
		this.loader.off();
	}

	getNotificationChatLink(): Promise<string> {
		return Promise.resolve('');
	}

	findServiceById(id: string): IService | undefined {
		return Object.values(SERVICES).find(s => s.id === id);
	}

	getServiceById(id: string): IService {
		return this.findServiceById(id) || SERVICES.takeaway;
	}

	init() {}

	getCustomConfig(): AppCustomConfig | undefined {
		return undefined;
	}

	getVisibleTowns(): ITown[] {
		return VISIBLE_TOWNS;
	}
}

export default CommonApplication;
