import { reactive, readonly } from 'vue';
import { getFilials } from '@/services/api';
import { ClientService } from '@/services/client';
import { PaginationWrapperModel } from '@/utils/models';
import { IPaginationWrapper } from '@/types/interfaces/common';
import { IFilial } from '@/types/interfaces/navigator';
import {
	ISearchFilialsState,
	ISearchFilialsService
} from '@/types/interfaces/search';

const state = reactive<ISearchFilialsState<IFilial>>({
	searchFilials: new PaginationWrapperModel(),
	hasError: false
});

const setSearchFilials = (
	data: IPaginationWrapper<IFilial>
): PaginationWrapperModel<IFilial> => {
	state.searchFilials.addResults(data);
	return state.searchFilials;
};

const clearSearchFilials = (): void => state.searchFilials.clearResults();

const getAutocomplete = (value: string): Promise<string[]> => {
	return getFilials({
		search: value,
		town: ClientService.state.townId,
		coordinates: ClientService.state.coordinates,
		service: ClientService.state.service.id,
		limit: 3
	}).then(response => [...new Set(response.results.map(el => el.name))]);
};

const fetchSearchFilials = (
	search: string
): Promise<PaginationWrapperModel<IFilial>> => {
	return getFilials({
		search,
		town: ClientService.state.townId,
		coordinates: ClientService.state.coordinates,
		service: ClientService.state.service.id
	}).then(response => {
		clearSearchFilials();
		return setSearchFilials(response);
	});
};

const loadMoreSearchFilials = (
	search: string
): Promise<PaginationWrapperModel<IFilial>> => {
	return new Promise((resolve, reject) => {
		if (state.hasError) {
			return resolve(state.searchFilials);
		}

		if (state.searchFilials.page < state.searchFilials.lastPage) {
			getFilials({
				search,
				town: ClientService.state.townId,
				coordinates: ClientService.state.coordinates,
				service: ClientService.state.service.id,
				page: state.searchFilials.page + 1
			})
				.then(response => resolve(setSearchFilials(response)))
				.catch(error => {
					state.hasError = true;
					reject(error);
				});
		}

		resolve(state.searchFilials);
	});
};

const SearchService: ISearchFilialsService<IFilial> = {
	state: readonly(state) as ISearchFilialsState<IFilial>,
	clearSearchFilials,
	getAutocomplete,
	fetchSearchFilials,
	loadMoreSearchFilials
};

export { SearchService };
export * from './search-products';
export * from './history';
