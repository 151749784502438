import ru from '@/assets/locales/ru.json';
import kk from '@/assets/locales/kk.json';

export default [
	{
		name: 'Қазақ тілі',
		code: 'kk',
		label: 'Қаз',
		messages: kk
	},
	{
		name: 'Русский язык',
		code: 'ru',
		label: 'Рус',
		messages: ru
	}
] as const;
