import amplitude from 'amplitude-js';
import { logAmplitudeEvent } from 'choco-app';
import providers from '@/utils/plugins/di';
import { ANALYTICS_INSTANCES } from './enums';

const isWebView = providers.application.isWebView();

abstract class AnalyticsCommon<EVENTS> {
	private key: string;
	name: string;
	isLoggable: boolean;
	isDev: boolean;

	constructor(key: string, name: string) {
		this.key = key;
		this.isLoggable = import.meta.env.VITE_ENV !== 'production';
		this.isDev = import.meta.env.VITE_ENV === 'local';
		this.name = name;
	}

	init() {
		amplitude.getInstance(this.name).init(this.key);
	}

	protected log(event: string, data: any, userId: string | null) {
		// логировать события не в production
		if (this.isLoggable) {
			console.log(event, data);
		}

		// отправлять события не в режиме разработки
		if (this.isDev) {
			return;
		}

		// использовать в вебвью нативный метод аналитики
		if (this.name === ANALYTICS_INSTANCES.TAKEAWAY && isWebView) {
			return logAmplitudeEvent(event, data);
		}

		amplitude.getInstance(this.name).setUserId(userId);
		amplitude.getInstance(this.name).logEvent(event, data);
	}

	abstract logEvent(event: EVENTS, payload: any): Promise<void>;
}

export { AnalyticsCommon };
